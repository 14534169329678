import './svg';

export const addOne = () => import('./system-icons/add-one.svg');
export const atmPin = () => import('./system-icons/atm-pin.svg');
export const bellRegular = () => import('./system-icons/bell-regular.svg');
export const bellSolid = () => import('./system-icons/bell-solid.svg');
export const bell = () => import('./system-icons/bell.svg');
export const budgets = () => import('./system-icons/budgets.svg');
export const call = () => import('./system-icons/call.svg');
export const camera = () => import('./system-icons/camera.svg');
export const chevronDown = () => import('./system-icons/chevron-down.svg');
export const chevronLeft = () => import('./system-icons/chevron-left.svg');
export const chevronRight = () => import('./system-icons/chevron-right.svg');
export const chevronUp = () => import('./system-icons/chevron-up.svg');
export const clock = () => import('./system-icons/clock.svg');
export const closeX = () => import('./system-icons/close-x.svg');
export const desktop = () => import('./system-icons/desktop.svg');
export const directions = () => import('./system-icons/directions.svg');
export const download = () => import('./system-icons/download.svg');
export const ellipsisH = () => import('./system-icons/ellipsis-h.svg');
export const ellipsisV = () => import('./system-icons/ellipsis-v.svg');
export const email = () => import('./system-icons/email.svg');
export const exclamationCircle = () =>
  import('./system-icons/exclamation-circle.svg');
export const exclamationTriangle = () =>
  import('./system-icons/exclamation-triangle.svg');
export const externalLink = () => import('./system-icons/external-link.svg');
export const file = () => import('./system-icons/file.svg');
export const filter = () => import('./system-icons/filter.svg');
export const fingerprint = () => import('./system-icons/fingerprint.svg');
export const hamburger = () => import('./system-icons/hamburger.svg');
export const hourglassHalf = () => import('./system-icons/hourglass-half.svg');
export const infoCircle = () => import('./system-icons/info-circle.svg');
export const location = () => import('./system-icons/location.svg');
export const locationArrow = () => import('./system-icons/location-arrow.svg');
export const mapMarker = () => import('./system-icons/map-marker.svg');
export const mobile = () => import('./system-icons/mobile.svg');
export const pause = () => import('./system-icons/pause.svg');
export const pencil = () => import('./system-icons/pencil.svg');
export const plus = () => import('./system-icons/plus.svg');
export const print = () => import('./system-icons/print.svg');
export const questionCircle = () =>
  import('./system-icons/question-circle.svg');
export const redo = () => import('./system-icons/redo.svg');
export const reloadPin = () => import('./system-icons/reload-pin.svg');
export const trash = () => import('./system-icons/trash.svg');
export const verified = () => import('./system-icons/verified.svg');
export const sortUp = () => import('./system-icons/sort-up.svg');
export const sortDown = () => import('./system-icons/sort-down.svg');
export const fingerprintAndroid = () =>
  import('./system-icons/android-fingerprint.svg');
export const faceId = () => import('./system-icons/apple-face-id.svg');
export const checkMark = () => import('./system-icons/check-mark.svg');
export const copy = () => import('./system-icons/copy.svg');
export const card = () => import('./system-icons/card.svg');
export const newAccount = () => import('./system-icons/new-account.svg');
export const circleCloseX = () => import('./system-icons/circle-close-x.svg');
export const backchevronLeft = () =>
  import('./system-icons/back-chevron-left.svg');
export const eye = () => import('./system-icons/eye.svg');
export const eyeSlash = () => import('./system-icons/eye-slash.svg');
export const piggyBank = () => import('./system-icons/piggy-bank.svg');
